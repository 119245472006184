'use client'
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded'
import BugReportIcon from '@mui/icons-material/BugReport'
import { Box } from '@mui/material'
import { SxProps } from '@mui/material/styles'
import cx from 'classnames'
import Image, { StaticImageData } from 'next/image'
import comma from 'src/assets/case_study/comma.png'
import logo from 'src/assets/logos/short_logo.png'
import Image2 from 'src/assets/svg/img35.svg'
import UP from 'src/assets/tools/UP.png'
interface StyledBoxProps {
  bgcolor?: string
  borderColor?: string
  className?: string
  style?: any
  children: React.ReactNode
}

export const HeadingTextSection = ({ text }: any) => {
  return <h2 className="text-2xl md:text-5xl text-[#E96A35] mb-8">{text}</h2>
}
export const HeroLabel = ({ text }: any) => {
  return <h2 className="font-normal text-base md:text-xl my-4">{text}</h2>
}
export const HeroHeading = ({ text }: any) => {
  return (
    <h2 className="leading-tight text-2xl md:text-4xl font-semibold  md:w-5/6 mx-auto">
      {text}
    </h2>
  )
}

export const QuoteText = ({ text }: any) => {
  return (
    <div className="text-sm md:text-[20px] leading-[20px] md:leading-[34px] text-center md:text-left">
      {text}
    </div>
  )
}

export const QuoteOwner = ({ text }: any) => {
  return (
    <div
      className="mt-6 text-stone-900
    md:text-base
    text-center
    md:text-left
    text-xs
    font-normal
    leading-normal"
    >
      <div className="ml-4 md:list-item md:list-outside md:list-square">
        {text[0]}
      </div>
      <div className="ml-4 font-light list-none list-inside">{text[1]}</div>
    </div>
  )
}

export const ListSection = ({ text }: any) => {
  return (
    <ul className="-mt-12 ml-6 leading-7 md:leading-[40px] text-sm md:text-[24px] list-square">
      {text?.map((item: string) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  )
}

export const DescriptionSection = ({ text }: any) => {
  return (
    <div className="text-[15px] leading-6 text-white -mt-4 md:text-[24px]">
      {text}
    </div>
  )
}

export const StyledBox = ({
  bgcolor,
  borderColor,
  style,
  className,
  children,
}: StyledBoxProps) => (
  <div
    className={` rounded-md ${className}`}
    style={{
      backgroundColor: bgcolor || 'white',
      border: `1px solid ${borderColor || '#D5D4D4'}`,
      ...style,
    }}
  >
    {children}
  </div>
)

interface Box1Props {
  title1: string
  title2: string
  title3?: string
  Img: StaticImageData
  className?: string
  style?: any
}
export const Box1 = (props: Box1Props) => {
  return (
    <div
      className={`${props?.className} p-20 text-center w-full`}
      style={{ paddingBottom: 400, ...props?.style }}
    >
      <Image
        style={{ height: 40, width: 'auto', margin: 'auto' }}
        src={props.Img}
        alt="Chargebacks"
      />
      <h6 className="font-normal text-base md:text-xl my-4">{props.title1}</h6>
      <h2 className="leading-tight text-2xl md:text-4xl font-semibold  md:w-5/6 mx-auto">
        {props.title2}
      </h2>
      {props?.title3 && (
        <h2 className="leading-tight text-2xl md:text-4xl font-semibold  md:w-5/6 mx-auto">
          {props.title3}
        </h2>
      )}
    </div>
  )
}

interface Box2Props {
  Img: StaticImageData | string
  words?: string
  name?: string
  designation?: string
}
export const Box2 = (props: Box2Props) => {
  return (
    <div className="relative">
      <div className="absolute top-20 -left-20">
        <div className="relative w-[200px] h-[400px]">
          <Image
            src="/testimonials/semi-circle.png"
            className="object-contain"
            alt="img"
            fill
          />
        </div>
      </div>
      <StyledBox
        className=" px-12 py-8 md:px-0 md:py-0 relative rounded-2xl bg-[url('/testimonials/back_img.png')] bg-no-repeat bg-contain bg-bottom "
        borderColor="rgba(232, 232, 232, 1)"
      >
        <div className="md:mb-16 md:mx-20 md:mt-16 w-fit h-fit flex flex-col md:flex-row gap-4">
          <Image className="h-[2rem]" src={comma} alt="img" />
          <div className="relative h-[210px] m-auto md:mt-8 w-full">
            <Image className="object-contain" src={props.Img} fill alt="img" />
          </div>

          <div className="md:pl-4 md:mt-8">
            <h2 className="text-[14px] md:text-[20px] leading-[20px] md:leading-[34px] text-center md:text-left">
              {props.words}
            </h2>
            <div className="ml-3">
              <ul className="mt-8 ml-4 list-square text-[12px] md:text-[16px] leading-[18px] md:leading-[24px]">
                <li className="pl-2">
                  {props.name}
                  <br />
                  {props.designation}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </StyledBox>
    </div>
  )
}

interface CardProps {
  title: string
  number?: string
  label?: string
  className?: string
  from?: boolean
  bold?: boolean
}

export const Card = ({
  label,
  number,
  title,
  className,
  from,
  bold,
}: CardProps) => {
  return (
    <div
      className={`${className} w-fit pt-5 md:min-h-[160px] bg-white flex flex-col justify-center md:justify-between gap-2`}
    >
      <h2
        className="text-base font-normal mb-2 text-center md:text-left"
        style={{ color: 'rgba(14, 140, 67, 1)' }}
      >
        {title}
      </h2>
      {number && (
        <h2 className="font-semibold md:text-5xl py-0 text-center  text-[#363A57] md:text-left">
          {number}
        </h2>
      )}
      {label && (
        <h2
          className={`text-center md:text-left text-[#363A57] md:text-[30px] md:leading-[32px] ${
            bold ? 'font-bold' : 'font-normal'
          }`}
        >
          {from && <span className="font-normal">From </span>}
          {label}
        </h2>
      )}
      <div
        className="w-[45px] h-[35px] rounded-3xl mt-1 mx-auto md:mx-0 flex justify-center items-center"
        style={{ background: 'rgba(218, 242, 228, 1)' }}
      >
        <Image className="h-[10px] w-[18px] object-cover" src={UP} alt="img" />
      </div>
    </div>
  )
}

interface Box3Props {
  title1?: string
  title2?: string
  title3?: string
  title4?: string
  redText?: string
  extraComp?: any
  className?: string
  className1?: string
  className2?: string
}

export const IntroSection = ({ introSectionProps }: any) => {
  return (
    <div
      className="w-full md:mb-8 px-12 md:px-36 py-32"
      style={{ background: 'rgba(18, 41, 55, 1)' }}
    >
      <div className="mx-auto flex flex-col gap-8 md:gap-[3rem] text-center md:text-left mb-8">
        <h2
          className={`font-normal text-3xl md:text-[50px]`}
          style={{ color: 'rgba(255, 201, 51, 1)' }}
        >
          {introSectionProps.title}
        </h2>
        <div className="md:w-[1145.67px] w-auto  text-white md:text-2xl text-xl leading-8 font-normal md:leading-10 text-">
          {introSectionProps?.description}
        </div>
      </div>

      <div className="md:w-[1138.90px] w-auto bg-gray-900 rounded-[20px] p-14 flex flex-col">
        <div className="relative w-auto h-[50px] mb-8">
          <div className="flex justify-start items-center relative before:h-1.5 before:w-32 before:rounded-md before:mr-8 before:text-white before:bg-[#F15412]">
            <Image
              src={logo}
              alt="img"
              className="py-3 px-[14px] bg-white rounded-full w-12 h-12"
            />
          </div>
          <div className="clear"></div>
        </div>

        <div className="md:w-[1039px] w-auto text-white text-2xl font-normal leading-10">
          {' '}
          {introSectionProps?.loopComments}
        </div>
      </div>
    </div>
  )
}

export const HeroSection = ({ heroSectionProps, className, children }: any) => {
  return (
    <div
      className={cx('p-20 md:px-44 px-4 pb-0 text-center w-full', className)}
      style={{ backgroundColor: `${heroSectionProps?.backGroundColor}` }}
    >
      <div className="">{children}</div>

      <TextTransformer textObject={heroSectionProps} className="mb-10" />
      <ImageFullPage
        imagePath={heroSectionProps.heroImg}
        className="border-8 border-black rounded-md border-b-0 -mb-0.5"
      ></ImageFullPage>
    </div>
  )
}

export const QuoteSection = ({ quoteSectionProps, className }: any) => {
  return (
    <>
      <div className={cx('relative', className)}>
        <StyledBox
          className="px-12 py-8 md:px-0 md:py-0 relative rounded-2xl bg-[url('/testimonials/back_img.png')] bg-no-repeat bg-contain bg-bottom"
          borderColor="rgba(232, 232, 232, 1)"
        >
          <div className="md:mb-16 md:mx-20 md:mt-16 w-fit h-fit flex flex-col md:flex-row gap-4 border p-8 rounded-md">
            <Image className="h-[2rem]" src={comma} alt="img" />
            <div className="relative h-44 m-auto md:mt-8 w-full">
              <Image
                className="object-contain"
                src={quoteSectionProps.imagePath}
                fill
                alt={quoteSectionProps.imageAlt}
              />
            </div>

            <div className="md:pl-4  md:mt-8 md:px-8 px-2">
              <TextTransformer textObject={quoteSectionProps} />
            </div>
          </div>
        </StyledBox>
      </div>
    </>
  )
}

export const PainPointSection = ({ painPointSectionProps }: any) => {
  return (
    <div
      className={`w-full px-12 md:px-36 pt-16 pb-20 mx-auto flex flex-col gap-8 md:gap-12`}
    >
      <div className="w-20 relative mb-4">
        <Image
          src={`/img/icons/${painPointSectionProps.iconImg}`}
          alt="img"
          width={0}
          height={0}
          className="w-full h-full object-contain"
        />
      </div>
      <h2 className="font-normal text-2xl md:text-5xl text-[#E96A35]  mb-6">
        {painPointSectionProps.headingText}
      </h2>

      <TextTransformer textObject={painPointSectionProps} />
    </div>
  )
}

export const TextTransformer = ({ textObject, className }: any) => {
  return (
    <div className={className}>
      {textObject?.section.map((item: any, index: number) => {
        if (item.type == 'heroLabel' && item.isShow) {
          return <HeroLabel key={item.key + index} text={item.text} />
        }
        if (item.type == 'heroHeading' && item.isShow) {
          return <HeroHeading key={item.key + index} text={item.text} />
        }

        if (item.type == 'quoteText' && item.isShow) {
          return <QuoteText key={item.key + index} text={item.text} />
        }

        if (item.type == 'quoteOwner' && item.isShow) {
          return <QuoteOwner key={item.key + index} text={item.text} />
        }

        if (item.type == 'headingText' && item.isShow) {
          return <HeadingTextSection key={item.key + index} text={item.text} />
        }

        if (item.type == 'list') {
          return <ListSection key={item.key + index} text={item.text} />
        }

        if (item.type == 'description') {
          return <DescriptionSection key={item.key + index} text={item.text} />
        }
      })}
    </div>
  )
}

export const SolutionAndResult = ({ solutionAndResultProps }: any) => {
  return (
    <div className="w-full bg-[#0E8C431F] py-16">
      <div className="container pb-20 mx-auto bg-white rounded-3xl">
        <div className="flex flex-col rounded-3xl bg-[#002112] relative px-16 py-12">
          {solutionAndResultProps?.iconImg && (
            <Image
              src={`/img/icons/${solutionAndResultProps.iconImg}`}
              alt="img"
              // fill={true}
              width={80}
              height={180}
              className="before:bg-[#0E8C43] before:rounded-md before:mr-8 before:text-white relative mb-4"
            />
          )}

          <h2 className="font-normal text-2xl md:text-5xl text-[#0E8C43] mb-6">
            {solutionAndResultProps.headingText}
          </h2>
          <h3
            className={`text-[15px]  leading-6 text-white ${
              solutionAndResultProps?.subHeadingText
                ? 'md:text-[17px]'
                : 'md:text-[24px]'
            }`}
          >
            {solutionAndResultProps.subHeadingText}
          </h3>
        </div>
        <div className="w-full md:px-20 px-8 mt-28">
          <TextTransformer textObject={solutionAndResultProps} />
        </div>
        <div className="bg-white w-full md:px-20 px-8  mt-28">
          <ImageFull
            imageObject={solutionAndResultProps.imageSection}
          ></ImageFull>
        </div>
      </div>
    </div>
  )
}

export const ImageFullPage = ({ imagePath, className }: any) => {
  return (
    <div
      className={cx(
        'flex justify-center items-center rounded overflow-hidden w-full h-full relative',
        className
      )}
    >
      <Image
        src={imagePath}
        alt="img"
        width={0}
        height={0}
        sizes="100vw"
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  )
}

export const ImageFull = ({ imageObject }: any) => {
  return (
    <>
      {imageObject?.map((item: any, index: number) => {
        if (item.type == 'full' && item.isShow) {
          return (
            <div
              key={item.key + index}
              className="flex justify-center items-center bg-[#FFE4BC] rounded md:p-12 p-4 overflow-hidden  w-full h-full relative"
            >
              <Image
                src={item.imagePath}
                alt="img"
                width={0}
                height={0}
                className="w-full h-full object-contain"
              />
            </div>
          )
        }
      })}
    </>
  )
}

export const Box3 = (props: Box3Props) => {
  return (
    <div
      className="w-full h-fit md:mb-8 px-12 md:px-36 py-32"
      style={{ background: 'rgba(18, 41, 55, 1)' }}
    >
      <div className="mx-auto flex flex-col gap-8  md:gap-[4.5rem] text-center md:text-left ">
        <h2
          className={`font-normal text-3xl md:text-[50px]`}
          style={{ color: 'rgba(255, 201, 51, 1)' }}
        >
          Introduction
        </h2>

        {props?.extraComp && (
          <div
            className={`w-full md:w-fit flex flex-col md:flex-row gap-4 items-center p-[20px] md:pl-[40px] rounded-[25px] md:rounded-full ${props?.className2}`}
            style={{ background: 'rgba(9, 28, 40, 1)' }}
          >
            <h2
              className="text-xl md:text-2xl"
              style={{ color: 'rgba(213, 247, 255, 1)' }}
            >
              The {props?.extraComp?.name} has
            </h2>
            <div className="flex gap-3 flex-wrap items-center justify-center">
              {props.extraComp?.list.map((item: string, i: number) => (
                <div
                  className={`w-fit h-fit px-4 py-2 text-sm md:text-[16px] bg-white rounded-3xl`}
                  key={item}
                >
                  <span
                    style={{
                      opacity: props?.extraComp?.isLight
                        ? i == 1
                          ? '30%'
                          : '1'
                        : '1',
                    }}
                  >
                    {item}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        <h2
          className={`text-white leading-7 md:leading-[40px] text-sm md:text-[24px] -mt-2 ${props?.className}`}
        >
          {props.title1}
        </h2>

        <div
          className={`p-8 md:px-24 md:py-20 rounded-3xl mt-8 -mb-10 flex flex-col items-center md:items-start gap-10 ${props?.className1}`}
          style={{ background: 'rgba(9, 22, 30, 1)' }}
        >
          <div className="flex items-center gap-4">
            <div
              className="h-0 w-[50px] md:w-[85px] rounded-full"
              style={{ border: '2.5px solid rgba(241, 84, 18, 1)' }}
            ></div>
            <div
              className="py-3 px-[14px] bg-white flex justify-center items-center"
              style={{ borderRadius: '50%' }}
            >
              <Image src={logo} alt="img" />
            </div>
          </div>
          <h2 className="text-white leading-7 md:leading-[40px] text-sm md:text-[24px]">
            {props.title2}
          </h2>
          {props?.title3 && (
            <h2 className="text-white leading-7 md:leading-[40px] text-sm md:text-[24px]">
              {props.title3}
            </h2>
          )}
          {props?.title4 && (
            <h2 className="text-white leading-7 md:leading-[40px] text-sm md:text-[24px]">
              {props.title4}
            </h2>
          )}
        </div>
      </div>
    </div>
  )
}

interface Box4Props {
  title?: string
  list?: string[]
  className?: string
}
export const Box4 = (props: Box4Props) => {
  return (
    <div
      className={` w-full px-12 md:px-36 pt-28 pb-20 mx-auto flex flex-col gap-8 md:gap-12 ${props?.className}`}
    >
      <Image src={Image2} alt="img" />
      <h2
        className="text-2xl md:text-5xl"
        style={{ color: 'rgba(233, 106, 53, 1)' }}
      >
        Pain Points:
      </h2>
      <h2 className="leading-7 md:leading-[40px] text-sm md:text-[24px]">
        {props?.title}
      </h2>
      {props?.list && (
        <ul
          className="-mt-12 ml-6 leading-7 md:leading-[40px] text-sm md:text-[24px]"
          style={{ listStyleType: 'number' }}
        >
          {props?.list?.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  )
}

interface Box5Props {
  label1?: string
  label2?: string
  extraComp?: any
  icon?: boolean
}
export const Box5 = (props: Box5Props) => {
  return (
    <div
      className="flex rounded-3xl items-end "
      style={{ background: 'rgba(0, 33, 18, 1)' }}
    >
      <div className="flex-1 flex flex-col gap-6 md:gap-12  p-8 md:pl-20 md:py-20">
        <div
          className="p-5 w-fit rounded-3xl -mt-4"
          style={{ background: 'rgba(14, 140, 67, 0.35)' }}
        >
          {props?.icon ? (
            <AddTaskRoundedIcon
              className="text-2xl md:text-4xl"
              style={{ color: 'rgba(14, 140, 67, 1)' }}
            />
          ) : (
            <BugReportIcon
              className="text-2xl md:text-4xl"
              style={{ color: 'rgba(14, 140, 67, 1)' }}
            />
          )}
        </div>
        <h2
          className="font-normal text-2xl md:text-5xl"
          style={{ color: 'rgba(14, 140, 67, 1)' }}
        >
          {props.label1}
        </h2>
        <h2
          className={`text-[15px]  leading-6 text-white -mt-4 ${
            props?.extraComp ? 'md:text-[17px]' : 'md:text-[24px]'
          }`}
        >
          {props.label2}
        </h2>
      </div>
      {props?.extraComp && (
        <div className="flex-2 pr-4 -pt-8">{props.extraComp}</div>
      )}
    </div>
  )
}

interface Box6Props {
  data: {
    img?: any
    img1?: any
    title?: string
    text?: string
    style?: object
  }
}
export const Box6 = (props: Box6Props) => {
  return (
    <div className="md:px-8 w-full flex flex-col md:h-[400px] md:flex-row gap-6 md:gap-16 bg-white items-center">
      <div
        className="w-full md:w-[45%] h-full rounded-2xl px-8 py-6 flex flex-col items-center justify-center"
        style={{ ...props.data.style }}
      >
        <Image src={props.data.img} alt="img" />
        {props.data?.img1 && <Image src={props.data.img1} alt="img" />}
      </div>
      <div className=" flex flex-col gap-6 md:gap-12 text-center md:text-left w-full md:w-[55%] items-center md:items-start">
        <h2 className="font-semibold text-2xl">{props.data.title}</h2>
        <h2 className="text-[16px] leading-7">{props.data.text}</h2>
      </div>
    </div>
  )
}

interface ShortCardProps {
  label?: string
  label1?: string
  img: StaticImageData
  className?: string
  className1?: string
  style?: any
  list?: string[]
}
export const ShortCard = (props: ShortCardProps) => {
  return (
    <div
      className={`flex flex-col gap-4 ${props?.className} bg-white w-full m-4 px-12 py-8 box-border rounded-xl`}
      style={{ ...props?.style }}
    >
      <Image
        className={`${props?.className1} w-[80px] h-[50px] object-scale-down`}
        src={props.img}
        alt="img"
      />
      <h2 className={`text-xl ${props?.list ? '' : 'md:w-[65%] '}`}>
        {props.label}
      </h2>
      {props?.label1 && (
        <ul
          className={`text-[12px] leading-6 text-left`}
          style={{ listStyleType: 'disc' }}
        >
          <li>
            {props?.label1}
            {props?.list && (
              <ul className="px-6 " style={{ listStyleType: 'disc' }}>
                {props?.list.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            )}
          </li>
        </ul>
      )}
    </div>
  )
}

interface Box7Props {
  data: {
    img: StaticImageData
    name: string
    designation: string
    words: string
    comma?: boolean
  }
}
export const Box7 = (props: Box7Props) => {
  return (
    <div
      className="flex flex-col p-10 mb-4 bg-white rounded-[14px] justify-between gap-12 items-center md:items-start"
      style={{ border: '0.69px solid rgba(230, 230, 230, 0.902)' }}
    >
      <div className="flex flex-col gap-6 items-center md:items-start">
        <div className="h-[30px] w-[35px]">
          {props.data?.comma && (
            <Image className="h-full w-full" src={comma} alt="img" />
          )}
        </div>
        <Image className=" object-cover" src={props.data.img} alt="img" />
        <h2 className="text-[14px] mt-4">{props.data.words}</h2>
      </div>
      <h6 className="text-sm">{props.data.name}</h6>
    </div>
  )
}

interface PropTypes {
  children: React.ReactNode
  id: string
  sx?: SxProps
}

export function SectionLink({ children, id, sx }: PropTypes) {
  // const location = useLocation()
  // const [params] = useSearchParams()
  // const sectionRef = React.useRef(null)
  // React.useEffect(() => {
  //   const section = params.get('section')
  //   if (section === id) sectionRef.current.scrollIntoView()
  // }, [params, sectionRef, id])
  return (
    <Box sx={sx} id={id}>
      {children}
    </Box>
  )
}
